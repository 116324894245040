export const SET_DATA_UNRECOVERABLE_ERROR = 'SET_DATA_UNRECOVERABLE_ERROR';

export const SET_DATA_API_HOST = 'SET_DATA_API_HOST';
export const SET_DATA_WEBTEXTS_HOST = 'SET_DATA_WEBTEXTS_HOST';
export const SET_JWT = 'SET_JWT';

export const SET_COURSE_ID = 'SET_COURSE_ID';
export const SET_COURSE_IMAGE_URL = 'SET_COURSE_IMAGE_URL';

export const SET_WEBTEXT_STYLE = 'SET_WEBTEXT_STYLE';

export const REQUIRED_ENTITIES_AVAILABLE = 'REQUIRED_ENTITIES_AVAILABLE';

export const SET_INITIAL_VIEW = 'SET_INITIAL_VIEW';
export const UPDATE_CURRENT_VIEW_PARAMS = 'UPDATE_CURRENT_VIEW_PARAMS';

export const GRID_VIEW_SCROLL_TO_COLUMN = 'GRID_VIEW_SCROLL_TO_COLUMN';

export const SET_HIGHLIGHT_TIMING = 'SET_HIGHLIGHT_TIMING';
export const SET_COLUMN_SCOPE_TYPE_SELECTABLE = 'SET_COLUMN_SCOPE_TYPE_SELECTABLE';
export const SET_ALL_SECTIONS_ROW_ENABLED = 'SET_ALL_SECTIONS_ROW_ENABLED';

export const SET_TOC_CHAPTER_IDS = 'SET_TOC_CHAPTER_IDS';
export const TOGGLE_TOC_CHAPTER_OPEN = 'TOGGLE_TOC_CHAPTER_OPEN';
export const SET_TOC_CHAPTER_OPEN = 'SET_TOC_CHAPTER_OPEN';
export const SET_ALL_TOC_CHAPTERS_OPEN = 'SET_ALL_TOC_CHAPTERS_OPEN';
export const SET_TOC_FILTERS = 'SET_TOC_FILTERS';
export const SET_TOC_FILTERING_ENABLED = 'SET_TOC_FILTERING_ENABLED';
export const SET_STUDENTS_FILTERS = 'SET_STUDENTS_FILTERS';
export const SET_STUDENTS_FILTERING_ENABLED = 'SET_STUDENTS_FILTERING_ENABLED';

export const JOB_IN_PROGRESS = 'JOB_IN_PROGRESS';
export const JOB_READY = 'JOB_READY';
export const JOB_COMPLETE = 'JOB_COMPLETE';
