export default {
	entities: {
		jobs: {},
		currentUser: {},
		course: {},
		featureFlags: {},
		enrollments: {},
		users: {},
		userIds: [],
		studentIds: [],
		toc: {},
		chapters: {},
		page_groups: {},
		pages: {},
		elements: {},
		scopeTypes: {},
		webtextBuilders: {},
		webtextPopupQuizzes: {},
		metrics: {},
		userActivitiesByUserId: {},
		pageActivities: {},
		overallClassPageActivity: {},
		elementAnswerIds: {},
		postedAnswerIds: {},
		answers: {},
		answerPostings: {},
		webtextComments: {},
		courseAverageCompletions: {},
		courseStdDevCompletions: {},
		courseCountVisited: {},
		courseUsercount: null,
		courseAverageScores: {},
		courseStdDevScores: {},
		courseLowScoreCutoffs: {},
		courseAverageTimings: {},
		courseStdDevTimings: {},
		courseLowTimingCutoffs: {},
		courseHighTimingCutoffs: {},
		courseTimingCounts: {},
		courseTimingLoaded: false,
		cohortAverageCompletions: {},
		cohortStdDevCompletions: {},
		cohortCountVisited: {},
		cohortUserCount: null,
		cohortAverageScores: {},
		cohortStdDevScores: {},
		cohortLowScoreCutoffs: {},
		cohortAverageTimings: {},
		cohortStdDevTimings: {},
		cohortLowTimingCutoffs: {},
		cohortHighTimingCutoffs: {},
		cohortTimingCounts: {},
		cohortTimingLoaded: true,
		cohortAverageCompletion: null,
		cohortAverageScore: null,
		cohortAverageTimeSpent: null,
		externalToolsConfigs: {}
	},
	data: {
		api: {},
		styles: {},
		webtexts: {},
		course: {},
		unrecoverableError: false,
		requiredEntitiesAvailable: false
	},
	jobs: {},
	ui: {
		viewTemplate: 'message',
		initialView: {},
		currentViewParams: {
			apiHost: null,
			courseId: null,
			scope: null,
			student: null,
			element: null,
			searchQuery: '',
			assignmentTypeFilter: '',
			gridViewColumnScopeType: 'chapter',
			gridViewAspect: 'progress',
			gridViewSortBy: 'user.last_name ASC',
			gridViewScrollTop: 0,
			gridViewScope: null,
			pageViewStudent: null,
			showInContext: false,
			scrollTo: null,
			welcomed: false
		},
		columnScopeTypeSelectable: true,
		highlightTiming: true,
		allSectionsRowEnabled: false
	},
	toc: {
		chapterIds: [],
		chapterOpen: {},
		filters: [],
		filteringEnabled: false
	},
	students: {
		filters: [],
		filteringEnabled: false
	}
};
