import React, { FC, useEffect, useMemo, useState } from 'react';
import Button, { ButtonProps } from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import cn from 'classnames';

import { getMetricsQuery } from 'Requests/MetricsQuery';
import { getElementsActivityQuery } from 'Requests/ElementsActivityQuery';
import { useAppSelector } from 'Store/index';
import {
	selectCourseId,
	selectGradingRequiredPages,
	selectRequiresGradingActionAnswersCount,
	selectRequiresGradingActionPagesSummary
} from 'Store/selectors';

import styles from './ShowNeedsGrading.scss';
import ViewAllAnswersList from 'Containers/QuickActionsSelector/ShowNeedsGradingButton/ViewAllAnswersList';
import { useRequests } from 'redux-query-react';

const ShowNeedsGradingButton: FC<ButtonProps> = (props) => {
	const courseId = useAppSelector(selectCourseId);
	const gradingRequiredPages = useAppSelector(selectGradingRequiredPages);

	const requireGradingActionPagesSummary = useAppSelector(selectRequiresGradingActionPagesSummary);
	const requiresGradingActionAnswersCount = useAppSelector(selectRequiresGradingActionAnswersCount);

	/**
	 * Obtaining metrics for the pages with grading required elements
	 * Needed to find pages with answers that are waiting for the instructor's grade
	 */
	const studentsIds = useAppSelector((state) => state.entities.studentIds);
	const requireGradingPagesMetricsRequests = useMemo(
		() =>
			gradingRequiredPages.map(({ chapter_id: pageChapterId }) =>
				getMetricsQuery({
					courseId,
					userIds: studentsIds,
					scopeId: pageChapterId,
					groupBy: 'page'
				})
			),
		[courseId, gradingRequiredPages, studentsIds]
	);
	const [{ isFinished: isMetricsLoaded }] = useRequests(requireGradingPagesMetricsRequests);

	/**
	 * Obtaining answers for the pages with grading required elements
	 * Needed to determine the answers that are waiting for the instructor's grade
	 */
	const requireGradingPagesAnswersRequests = useMemo(
		() =>
			!isMetricsLoaded
				? null // Don't make requests until metrics are gathered and summary is aggregated
				: requireGradingActionPagesSummary.flatMap(
						({ family_id: pageId, student_ids: studentIds, element_ids: elementIds }) =>
							studentIds.map((studentId) =>
								getElementsActivityQuery({ courseId, pageId, userId: studentId, elementIds })
							)
				  ),
		[courseId, isMetricsLoaded, requireGradingActionPagesSummary]
	);
	const [{ isFinished: isAnswersLoaded }] = useRequests(requireGradingPagesAnswersRequests);

	const isLoaded = isMetricsLoaded && isAnswersLoaded;

	if (gradingRequiredPages.length === 0) return null;
	return (
		<Button
			{...props}
			classes={{
				...props.classes,
				root: `${props.classes.root} ${styles.SelectionButton}`
			}}
			disabled={!isLoaded}>
			<div className={styles.LabelContainer}>
				<span>{props.children}</span>
				{isLoaded ? (
					<span
						className={cn(
							styles.NeedsGradingCounter,
							requiresGradingActionAnswersCount === 0 && styles.NeedsGradingCounter__Zero
						)}>
						{requiresGradingActionAnswersCount}
					</span>
				) : (
					<CircularProgress color="inherit" size={20} />
				)}
			</div>
			{isLoaded && <ViewAllAnswersList />}
		</Button>
	);
};

export default ShowNeedsGradingButton;
